import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import ReactPlayer from 'react-player/vimeo';

import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import { useLocaleContext } from '../../context/Locale';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import CommonData from '../../data/common/common';
import './style.scss';

type Props = PageProps & WithTranslation;

const VideoPage: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  const { activeLocaleTag, originalPath } = useLocaleContext();
  const { context } = (data as any).allSitePage.edges[0].node;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(context.titleKey),
    },
    page: {
      title: t(context.titleKey),
    },
  };

  const getTransComponentProps = () => {
    if (originalPath) {
      if (
        originalPath ===
        getPathFromRouteKey(RouteKeys.BROKER_CENTRE.VIDEO_LIBRARY.SECONDS_PLUS_MORTGAGE)
      ) {
        return {
          children: <ExternalLink href={CommonData.websites.brokerPortal[activeLocaleTag]} />,
        };
      }
    }

    return {};
  };
  return (
    <Layout options={layoutOptions} className="VideoPage">
      <div className="grid-container VideoPage__max-width-container">
        <div className="row row-wrap VideoPage__flex-row VideoPage__generic-gap">
          <div className="column column-100">
            <div className="VideoPage__player-wrapper">
              <ReactPlayer
                url={context.videoUrl[activeLocaleTag]}
                className="react-player"
                width="100%"
                height="100%"
                controls
              />
            </div>
            <Trans i18nKey={context.descriptionKey} t={t} {...getTransComponentProps()} />
          </div>
          <ButtonLink
            to={getPathFromRouteKey(RouteKeys.BROKER_CENTRE.VIDEO_LIBRARY)}
            styleOptions={{ isInline: true }}
          >
            {t('watchMoreVideos')}
          </ButtonLink>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            descriptionKey
            titleKey
            videoUrl {
              en
              fr
            }
          }
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.VIDEO_LIBRARY)(VideoPage);
